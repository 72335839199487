import * as types from "../constants";

const initialContractHeadCells = [
	{ id: "Description", alignment: "left", active: true },
	{ id: "Version", alignment: "right", active: true },
	{ id: "DocumentDate", alignment: "left", active: true },
];

export default function contractHeadCellsReducer(
	state = initialContractHeadCells,
	actions
) {
	switch (actions.type) {
		case types.SET_CONTRACT_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}

// Déclaration statique de tout les fichiers JSON pour que le compilateur React les mettent dans le bundle final

import LangActivityAccountEntry from './LangActivityAccountEntry.json';
import LangActivityReport from './LangActivityReport.json';
import LangCharge from './LangCharge.json';
import LangCommercialAgreement from './LangCommercialAgreement.json';
import LangContact from './LangContact.json';
import LangContractor from './LangContractor.json';
import LangContracts from './LangContracts.json';
import LangCustomer from './LangCustomer.json';
import LangCustomerAccountEntry from './LangCustomerAccountEntry.json';
import LangDashboard from './LangDashboard.json';
import LangGeneral from './LangGeneral.json';
import LangInfraAccess from './LangInfraAccess.json';
import LangInvoiceCreditNotes from './LangInvoiceCreditNotes.json';
import LangInvoiceRequest from './LangInvoiceRequest.json';
import LangLogin from './LangLogin.json';
import LangRecurrentInvoice from './LangRecurrentInvoice.json';
import LangSalesInvoiced from './LangSalesInvoiced.json';
import LangSettings from './LangSettings.json';
import LangTasks from './LangTasks.json';
import LangTickets from './LangTickets.json';
import LangTicketsCategory from './LangTicketsCategory.json';
import LangTraining from './LangTraining.json';
import LangWage from './LangWage.json';
import LangWorkContract from './LangWorkContract.json';
import LangServicePrices from './LangServicePrices.json';
import LangProductSheets from './LangProductSheets.json';

export default {
	langs: {
		FRA: 'fr',
		ENU: 'en'
	},
	resources: [
		LangActivityAccountEntry,
		LangActivityReport,
		LangCharge,
		LangCommercialAgreement,
		LangContact,
		LangContractor,
		LangContracts,
		LangCustomer,
		LangCustomerAccountEntry,
		LangDashboard,
		LangGeneral,
		LangInfraAccess,
		LangInvoiceCreditNotes,
		LangInvoiceRequest,
		LangLogin,
		LangRecurrentInvoice,
		LangSalesInvoiced,
		LangSettings,
		LangTasks,
		LangTickets,
		LangTicketsCategory,
		LangTraining,
		LangWage,
		LangWorkContract,
		LangServicePrices,
		LangProductSheets,
	]
};

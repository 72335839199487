import React from "react";
import ReactExport from "react-export-excel";

import {
  IconButton,
  Tooltip
} from "@material-ui/core";

import { useTranslation } from "react-i18next";

import * as Icon from "react-feather";


const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


export default function ExportExcel(props) {
  const { t } = useTranslation();
  
  const TrimId = str => str.trim().split(/(?=[A-Z])/).join('-').toLowerCase();


  const {values, headers, title} = props;
  return (
    <Tooltip title={t('table-export')}>
      <ExcelFile filename={title} element={<IconButton><Icon.Download/></IconButton>}>
        <ExcelSheet data={values} name={title}>
          {headers.filter(item => {
            if(item.id !== "" && item.active === true ){
              return item;
            }
            return false;
          }).map(header => {
            if(header.id ===  "LinkedContractor"){
              return <ExcelColumn key={header.id} label={t(TrimId(header.id))} value={(col) => col.LinkedContractor.FirstName + " " + col.LinkedContractor.LastName}/>
            } else if (header.id === "CAStatus"){
              return <ExcelColumn key={header.id} label={t(TrimId(header.id))} value={(col) => {
                let value = "";
                switch(col.CAStatus){
                  case 0:
                    value = t('status-initialized');
                    break;
                  case 1:
                    value = t('status-sent');
                    break;
                  case 2:
                    value = t('status-active');
                    break;
                  case 3:
                    value = t('status-extended');
                    break;
                  case 4:
                    value = t('status-end');
                    break;
                  case 5:
                    value = t('status-done');
                    break;
                  case 6:
                    value = t('status-monitor');
                    break;
                  default:
                    console.log("Wrong value in CAStatus");
                }
                return value;
              }}/>
            }else if(header.id === "ContractOwnerFirstName"){
              return <ExcelColumn key={t(TrimId(header.id))} label={t(TrimId(header.id))} value={(col) =>{ 
                if(col.ContractOwnerFirstName !== null){
                  return col.ContractOwnerFirstName + " " + col.ContractOwnerLastName;
                }else{
                  return "";
                }
              }}/>
            }else if(header.id.includes("Date")){
              return <ExcelColumn key={t(TrimId(header.id))} label={t(TrimId(header.id))} value={(col) =>{ 
                return new Date(col[header.id]).toLocaleDateString();
              }}/>
            }
            else if(header.id === "ContractorFirstName"){
              return <ExcelColumn key={t(TrimId(header.id))} label={t(TrimId(header.id))} value={(col) =>{
                return col.ContractorLastName + " " +  col.ContractorFirstName;
              }}/>
            }
            else{
              return <ExcelColumn key={header.id} label={t(TrimId(header.id))} value={header.id}/>
            }
          })}
        </ExcelSheet>
      </ExcelFile>
    </Tooltip>
  );
}
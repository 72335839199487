import * as types from "../constants";

const initialRecurrentInvoicesHeadCells = [
	{ id: "DocumentNo", alignment: "right", active: true },
	{ id: "Description", alignment: "left", active: true },
	{ id: "Quantity", alignment: "right", active: true },
	{ id: "UnitPrice", alignment: "right", active: true },
	{ id: "Frequency", alignment: "left", active: true },
	{ id: "FirstDueDate", alignment: "left", active: true },
	{ id: "NextDueDate", alignment: "left", active: true },
	{ id: "LastDueDate", alignment: "left", active: true },
];

export default function recurrentInvoicesReducer(
	state = initialRecurrentInvoicesHeadCells,
	actions
) {
	switch (actions.type) {
		case types.SET_RECURRENT_INVOICES_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}

import { library } from '@fortawesome/fontawesome-svg-core';
import { faCircle as farCircle } from '@fortawesome/free-regular-svg-icons';
import { faAdjust, faCircle as fasCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@material-ui/core';
import { blue, green, orange, red } from "@material-ui/core/colors";
import {
	NotificationImportant as EmergencyIcon,
	RadioButtonChecked as BlockingIcon
} from '@material-ui/icons';
import React from 'react';
import { useTranslation } from "react-i18next";


library.add(fasCircle, farCircle, faAdjust);

export default function PriorityChip(props) {
	const { t } = useTranslation();
	const { priority, ...rest } = props;
	let color = {};

	if (priority === undefined || priority === "")
		return null;

	switch(priority) {
		case 0: case "Low":       color.color = blue[500], color.borderColor = blue[500]; break;
		case 1: case "Normal":    color.color = green[800], color.borderColor = green[800]; break;
		case 2: case "High":      color.color = orange[500], color.borderColor = orange[500]; break;
		case 3: case "Emergency": color.color = red[500], color.borderColor = red[500]; break;
		case 4: case "Blocking":  color.color = red[700], color.borderColor = red[700]; break;
	}

	return (
		<Chip
			{...rest}
			style={color}
			variant="outlined"
			icon={(() => {
				switch(priority) {
					case 0: case "Low":       return (<FontAwesomeIcon style={color} icon={farCircle} />);
					case 1: case "Normal":    return (<FontAwesomeIcon style={color} icon={faAdjust} rotation={270} />);
					case 2: case "High":      return (<FontAwesomeIcon style={color} icon={fasCircle} />);
					case 3: case "Emergency": return (<EmergencyIcon style={color} />);
					case 4: case "Blocking":  return (<BlockingIcon style={color} />);
					default: console.warn(`Unhandled priority "${priority}" in PriorityChip.js!`);
				}
			})()}
			label={(() => {
				switch(priority) {
					case 0: case "Low":       return t('priority-low');
					case 1: case "Normal":    return t('priority-normal');
					case 2: case "High":      return t('priority-high');
					case 3: case "Emergency": return t('priority-emergency');
					case 4: case "Blocking":  return t('priority-blocking');
					default: console.warn(`Unhandled priority "${priority}" in PriorityChip.js!`);
				}
			})()}
		></Chip>
	);
}

import { green, grey } from "@material-ui/core/colors";

const greenVariant = {
  name: "Green",
  palette: {
    primary: {
      main: "#62b852",
      contrastText: "#FFF"
    },
    secondary: {
      main: grey[500],
      contrastText: "#FFF"
    }
  },
  header: {
    color: grey[500],
    background: "#FFF",
    search: {
      color: grey[800]
    },
    indicator: {
      background: green[400]
    }
  },
  sidebar: {
    color: "#FFF",
    background: green[500],
    header: {
      color: "#FFF",
      background: "#62b852",
      brand: {
        color: "#FFFFFF"
      }
    },
    footer: {
      color: "#FFF",
      background: "#62b852",
      online: {
        background: "#FFF"
      }
    },
    category: {
      fontWeight: 400
    },
    badge: {
      color: "#000",
      background: "#FFF"
    }
  },
  body: {
    background: "#F7F9FC"
  },
  footer: {
    color: grey[800]
  }
};

const variants = [
  greenVariant
];

export default variants;

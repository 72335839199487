import { Box } from "@material-ui/core";
import { _arrayBufferToBase64 } from "api/files";
import { DropzoneArea } from "material-ui-dropzone";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";

function ChooseFile({ fileToUpload, setFileToUpload }) {
	const { t } = useTranslation();
	const [uploadLoading, setUploadLoading] = useState(false);

	function checkFileType(acceptedFiles, file) {
		const fileType = file.type;
		return acceptedFiles.some((acceptedType) => {
			const acceptedTypeRegex = new RegExp(acceptedType.replace("*", ".*"), "i");
			return acceptedTypeRegex.test(fileType);
		});
	}

	return (
		<Box>
			<DropzoneArea
				showFileNamesInPreview
				showFileNames
				filesLimit={1}
				dropzoneText={fileToUpload?.Name || t("ChooseFile")}
				getFileAddedMessage={(fileName) => `Fichier ${fileName} ajouté avec succès.`}
				getFileRemovedMessage={(fileName) => `Fichier ${fileName} supprimé.`}
				getFileLimitExceedMessage={(filesLimit) =>
					`Trop de fichiers sélectionnés, seulement ${filesLimit} autorisé`
				}
				getDropRejectMessage={(rejectedFile, acceptedFiles, maxFileSize) => {
					console.log({ rejectedFile, acceptedFiles, maxFileSize });

					if (rejectedFile.size > maxFileSize)
						return `Le fichier ${rejectedFile.name} est trop volumineux.`;
					else if (!checkFileType(acceptedFiles, rejectedFile))
						return `Le fichier ${rejectedFile.name} n'est pas au bon format.`;
					else return `Le fichier ${rejectedFile.name} à été rejeté.`;
				}}
				onChange={(files) => {
					if (files.length !== 1) {
						setFileToUpload({ ...fileToUpload, Name: "", Bytes: "" });
						return;
					}

					const { name } = files[0];

					const reader = new FileReader();
					reader.onload = function (event) {
						const bytes = _arrayBufferToBase64(event.target.result);
						setFileToUpload({
							...fileToUpload,
							Name: name,
							Bytes: bytes,
						});
						setUploadLoading(false);
					};
					reader.readAsArrayBuffer(files[0]);
					setUploadLoading(true);
				}}
			/>
			{uploadLoading && <div>Loading...</div>}
		</Box>
	);
}

export default ChooseFile;

import {
	ConfirmationNumber as TicketIcon,
	Dashboard as DashboardIcon,
	Feedback as FeedbackIcon,
	Receipt as ReceiptIcon,
	Storage as StorageIcon,
} from "@material-ui/icons";
import { userMenuFilter } from "api";
import React from "react";
import { User, Users } from "react-feather";
import async from "../components/Async";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faFileSignature, faUserClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { NewTicketButton } from "../pages/pages/Tickets";
library.add(faFileSignature, faUserClock);

// Auth components
const SignIn = async(() => import("../pages/auth/SignIn"));
const ResetPassword = async(() => import("../pages/auth/ResetPassword"));
const Page404 = async(() => import("../pages/auth/Page404"));
const Page500 = async(() => import("../pages/auth/Page500"));
const PageNetworkError = async(() => import("../pages/auth/PageNetworkError"));
const PrivacyPolicy = async(() => import("../pages/pages/PrivacyPolicy"));

// Pages components
const Profile = async(() => import("../pages/pages/Profile"));
const Umbrella = async(() => import("../pages/pages/Umbrella"));
const Tickets = async(() => import("../pages/pages/Tickets"));
const TicketDetails = async(() => import("../pages/pages/TicketDetails"));
const TicketsCategory = async(() => import("../pages/pages/TicketsCategory"));
const Tasks = async(() => import("../pages/pages/TasksList"));
const TaskDetails = async(() => import("../pages/pages/TaskDetails"));
const RecurrentInvoices = async(() => import("../pages/pages/RecurrentInvoicesList"));
const InvoiceCreditNotes = async(() => import("../pages/pages/InvoiceCreditNotes"));
const CustomerAccountEntryList = async(() => import("../pages/pages/CustomerAccountEntryList"));
const Contracts = async(() => import("../pages/pages/Contracts"));
const ServicePrices = async(() => import("../pages/pages/ServicePrices"));
const ProductSheets = async(() => import("../pages/pages/ProductSheets"));

const authRoutes = {
	id: "Auth",
	path: "/auth",
	icon: <Users />,
	children: [
		{
			path: "/auth/login",
			name: "Connexion",
			component: SignIn,
		},
		{
			path: "/auth/reset-password",
			name: "Reset Password",
			component: ResetPassword,
		},
		{
			path: "/404",
			name: "Page non trouvée",
			component: Page404,
		},
		{
			path: "/500",
			name: "Erreur interne",
			component: Page500,
		},
		{
			path: "/networkerror",
			name: "Erreur réseau",
			component: PageNetworkError,
		},
	],
};

const privacyPolicyRoutes = {
	id: "privacy",
	path: "/privacy-policy",
	component: PrivacyPolicy,
	children: null,
};

const profileRoutes = {
	id: "profile",
	path: "/profil",
	icon: <User />,
	component: Profile,
	children: null,
};

const createTicketRoute = {
	id: "ticket",
	type: "button",
	component: <NewTicketButton />,
};

const umbrellaRoutes = {
	id: "umbrella",
	path: "/",
	icon: <DashboardIcon />,
	component: Umbrella,
	children: null,
	isHome: true,
};

const ticketsList = {
	id: "tickets",
	path: "/tickets",
	icon: <TicketIcon />,
	children: [
		{
			path: "/tickets/<>closed",
			name: "ticket-not-closed",
			component: Tickets,
		},
		{
			path: "/tickets/draft copy",
			name: "ticket-draft_copy",
			component: Tickets,
		},
		{
			path: "/tickets/open",
			name: "ticket-open",
			component: Tickets,
		},
		{
			path: "/tickets/pending",
			name: "ticket-pending",
			component: Tickets,
		},
		{
			path: "/tickets/in progress",
			name: "ticket-in_progress",
			component: Tickets,
		},
		{
			path: "/tickets/closed",
			name: "ticket-closed",
			component: Tickets,
		},
		{
			path: "/tickets",
			name: "tickets-all",
			component: Tickets,
		},
		{
			path: "/tickets-category",
			name: "tickets-category",
			component: TicketsCategory,
		},
	],
};

const tasksList = {
	id: "tasks",
	path: "/tasks",
	icon: <FeedbackIcon />,
	component: Tasks,
	children: [
		{
			path: "/tasks/initialized",
			name: "task-initialized",
			component: Tasks,
		},
		{
			path: "/tasks/signature",
			name: "task-signature",
			component: Tasks,
		},
		{
			path: "/tasks/in progress",
			name: "task-in_progress",
			component: Tasks,
		},
		{
			path: "/tasks/delivered in test",
			name: "task-delivered_in_test",
			component: Tasks,
		},
		{
			path: "/tasks/customer validated",
			name: "task-customer_validated",
			component: Tasks,
		},
		{
			path: "/tasks/in production",
			name: "task-in_production",
			component: Tasks,
		},
		{
			path: "/tasks/denied",
			name: "task-denied",
			component: Tasks,
		},
		{
			path: "/tasks/billable",
			name: "tasks-billable",
			component: Tasks,
		},
		{
			path: "/tasks",
			name: "tasks-all",
			component: Tasks,
		},
	],
};

const taskDetails = {
	id: "task",
	path: "/task",
	icon: <FeedbackIcon />,
	component: TaskDetails,
	children: null,
};

const ticketDetails = {
	id: "ticket",
	path: "/tickets/:no",
	icon: <TicketIcon />,
	component: TicketDetails,
	children: null,
};

const recurrentInvoices = {
	id: "recurrent-invoices",
	path: "/recurrent-invoices",
	icon: <StorageIcon />,
	component: RecurrentInvoices,
	children: null,
};

const invoiceCreditNotes = {
	id: "invoice-credit-notes",
	path: "/invoice-credit-notes",
	icon: <ReceiptIcon />,
	component: InvoiceCreditNotes,
	children: null,
};

const customerAccountEntryList = {
	id: "customer-account",
	path: "/customer-account",
	icon: <ReceiptIcon />,
	component: CustomerAccountEntryList,
	children: null,
};

const contracts = {
	id: "contracts-menu-title",
	path: "/contracts",
	icon: <FontAwesomeIcon icon={faFileSignature} />,
	component: Contracts,
	children: null,
};

const servicePrices = {
	id: "service-prices-menu-title",
	path: "/service-prices",
	icon: <FontAwesomeIcon icon={faUserClock} />,
	component: ServicePrices,
	children: null,
};

const productSheets = {
	id: "product-sheets-menu-title",
	path: "/product-sheets",
	icon: <ListAltIcon />,
	component: ProductSheets,
	children: null,
};

export const dashboard = [
	umbrellaRoutes,
	profileRoutes,
	ticketsList,
	ticketDetails,
	tasksList,
	taskDetails,
	recurrentInvoices,
	invoiceCreditNotes,
	customerAccountEntryList,
	servicePrices,
	contracts,
	productSheets,
].filter(userMenuFilter);

export const auth = [authRoutes];

export const privacy = [privacyPolicyRoutes];

export default [
	createTicketRoute,
	umbrellaRoutes,
	ticketsList,
	tasksList,
	recurrentInvoices,
	invoiceCreditNotes,
	customerAccountEntryList,
	servicePrices,
	contracts,
	productSheets,
].filter(userMenuFilter);

import * as types from "../constants";

const initialCustomerAccountHeadCells = [
	{ id: "DueState", alignment: "left", active: true },
	{ id: "PostingDate", alignment: "left", active: true },
	{ id: "CustomerNo", alignment: "left", active: true },
	{ id: "DocumentNo", alignment: "left", active: true },
	{ id: "Description", alignment: "left", active: true },
	{ id: "DueDate", alignment: "left", active: true },
	{ id: "OriginalAmount", alignment: "right", active: true },
	{ id: "RemainingAmount", alignment: "right", active: true },
];

export default function customerAccountHeadCellsReducer(
	state = initialCustomerAccountHeadCells,
	actions
) {
	switch (actions.type) {
		case types.SET_CUSTOMER_ACCOUNT_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}

import {
	Grid,
	// Hidden,
	// List,

	// ListItem as MuiListItem, ListItemText,

	Typography as MuiTypography
} from "@material-ui/core";
import React from "react";
import styled from "styled-components";


import { useTranslation } from "react-i18next";
import { Link as MuiLink } from "react-router-dom";

const Wrapper = styled.div`
  padding: ${props => props.theme.spacing(4) / 2}px
    ${props => props.theme.spacing(4)}px;
  background: ${props => props.theme.palette.common.white};
  position: relative;
`;

// const ListItem = styled(MuiListItem)`
//   display: inline-block;
//   width: auto;
//   padding-left: ${props => props.theme.spacing(2)}px;
//   padding-right: ${props => props.theme.spacing(2)}px;

//   &,
//   &:hover,
//   &:active {
//     color: #000;
//   }
// `;

const Link = styled(MuiLink)`
  color: ${props => props.theme.footer.color};
`;

const Typography = styled(MuiTypography)`
  color: ${props => props.theme.footer.color};
`;

function Footer() {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Grid container justify="space-between" alignItems="center">
				<Grid item xs={6}>
					<Link to="/privacy-policy">{t('privacy-policy')}</Link>
				</Grid>
        <Grid item xs={6} style={{ textAlign: "right" }}>
					<Typography variant="body2">{`© ${new Date().getFullYear()} - Europe Tech Group`}</Typography>
        </Grid>
      </Grid>
    </Wrapper>
  );
}

export default Footer;

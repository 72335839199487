import * as types from "../constants";

const initialTasksHeadCells = [
	{ id: "FolderTask", type: "string", primary: true },
	{ id: "FolderCode", type: "string", primary: true },
	{ id: "TaskBeginDate", type: "date" },
	{ id: "DevTechName", type: "string", bold: true },
	{ id: "Description", type: "string" },
	{ id: "TaskStatus", type: "enum" },
	{ id: "InitialQuantity", type: "float" },
	{ id: "WorkedQuantity", type: "float" },
	{ id: "ProgressPercentage", type: "progress" },
	{ id: "PracticeMethodType", type: "string", active: false },
	{
		id: "FolderService",
		type: "string",
		primary: true,
		active: false,
	},
];

export default function tasksHeadCellsReducer(state = initialTasksHeadCells, actions) {
	switch (actions.type) {
		case types.SET_TASKS_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}

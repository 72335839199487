import * as types from "../constants";

const initialProductSheetHeadCells = [
	{ id: "Description", alignment: "left", active: true },
	// { id: 'Version', alignment: 'right', active:true },
	{ id: "CreationDate", alignment: "left", active: true },
	// { id: 'Type', alignment: 'left', active:true },
	// { id: 'File', alignment: 'left', active:true },
	// { id: 'Folder', alignment: 'left', active:true },
	// { id: 'SubFolder', alignment: 'left', active:true },
	// { id: 'CustomerName', alignment: 'left', active:true },
];

export default function productSheetHeadCellsReducer(
	state = initialProductSheetHeadCells,
	actions
) {
	switch (actions.type) {
		case types.SET_PRODUCT_SHEETS_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}

import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	FormGroup,
	IconButton,
	Input,
	InputLabel,
	MenuItem,
	Button as MuiButton,
	Divider as MuiDivider,
	Grid as MuiGrid,
	Paper,
	Select,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TablePagination,
	TableRow,
	TextField,
	Toolbar,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { blue, green, orange, red } from "@material-ui/core/colors";
import { Add, Settings as SettingsIcon } from "@material-ui/icons";
import { Alert, Skeleton } from "@material-ui/lab";
import { withStyles } from "@material-ui/styles";
import { spacing } from "@material-ui/system";
import { LoadingButton } from "pages/components/Buttons";
import ChooseFile from "pages/components/ChooseFile";
import { useCustomSnackbar } from "pages/components/Snackbar";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import debounce from "../../api/debounce";
import { fetcher, default as useFetch } from "../../api/fetcher";
import * as types from "../../redux/constants";
import variants from "../../theme/variants";
import EnhancedTableHead from "../components/EnhancedTableHead";
import ExportExcel from "../components/ExportToExcel";
import PriorityChip from "../components/PriorityChip";
import Search from "../components/Search";
import StatusChip from "../components/StatusChip";

const ToolbarTitle = styled.div`
	min-width: 150px;
`;

const Spacer = styled.div`
	flex: 1 1 20%;
`;

const Divider = styled(MuiDivider)(spacing);
const Button = styled(MuiButton)(spacing);
const Grid = styled(MuiGrid)(spacing);

const StyledTableRow = withStyles((theme) => ({
	root: {
		"&:nth-of-type(odd)": {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] === undefined) return 0;

	if (typeof a[orderBy] !== "string" && typeof a[orderBy] !== "number" && a[orderBy] !== null) {
		if (b[orderBy].FirstName < a[orderBy].FirstName) return -1;
		if (b[orderBy].FirstName > a[orderBy].FirstName) return 1;
	} else {
		let valueA = a[orderBy],
			valueB = b[orderBy];

		if (a[orderBy] === null) valueA = "";
		else if (b[orderBy] === null) valueB = "";
		if (valueB < valueA) return -1;
		if (valueB > valueA) return 1;
	}

	return 0;
}

function getComparator(order, orderBy) {
	return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
	if (!Array.isArray(array)) return [];

	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) return order;
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function TableColumnPicker(props) {
	const { headCells, headCellsHandler } = props;
	const { t } = useTranslation();

	// Récupération automatique de la traduction selon l'id
	const trad = (key) =>
		t(
			key
				.trim()
				.split(/(?=[A-Z])/)
				.join("-")
				.toLowerCase()
		);

	return (
		<FormGroup>
			{headCells.map((headCell) =>
				headCell.id !== "" ? (
					<FormControlLabel
						key={headCell.id}
						label={trad(headCell.id)}
						control={<CustomCheckBox updateHandler={headCellsHandler} headCell={headCell} />}
					/>
				) : null
			)}
		</FormGroup>
	);
}

function CustomCheckBox(props) {
	const { headCell, updateHandler } = props;
	const [isActive, setIsActive] = useState(headCell.active);

	const handleChange = () => {
		headCell.active = !isActive;
		setIsActive(headCell.active);
		updateHandler();
	};

	return <Checkbox checked={isActive} onClick={handleChange} label={headCell.label} />;
}

function EnhancedTable({ rows, pathname }) {
	const [order, setOrder] = useState("desc");
	const [orderBy, setOrderBy] = useState("CreationDate");
	const [page, setPage] = useState(0);
	const [searchFilteredRows, setSearchFilteredRows] = useState();
	const [dialog, setDialog] = useState(false);

	const { t } = useTranslation();

	// Les méthodes Redux se substituent aux methodes du useState pour conserver une compatibilité
	// avec les composants qui y font réference (EnhancedTable)
	// [headCells, setHeadCells] est remplacé par un 'state Redux', il est initialisé par le 'reducer'
	const dispatch = useDispatch();
	const setHeadCells = (cells) => dispatch({ type: types.SET_TICKETS_HEAD_CELLS, payload: cells });
	const headCells = useSelector((state) => state.ticketsHeadCellsReducer);
	// const [headCells, setHeadCells] = useState([
	// 	{ id: 'No', alignment: 'left', active:true },
	// 	{ id: 'Category', alignment: 'left', active:true },
	// 	{ id: 'Title', alignment: 'left', active:true },
	// 	{ id: 'CreationDate', alignment: 'left', active:true },
	// 	{ id: 'Priority', alignment: 'left', active:true },
	// 	{ id: 'Status', alignment: 'left', active:true },
	// 	{ id: 'ContactName', alignment: 'left', active:true },
	// 	{ id: 'AssignedTo', alignment: 'left', active:true },
	// ]);

	// const [rowsPerPage, setRowsPerPage] = useState(25);
	// implementation redux / redux-persist pour le nombre de lignes affichées
	const rowsPerPage = useSelector((state) => state.rowsPerPageReducer);
	const setRowsPerPage = (rows) => dispatch({ type: types.SET_ROWS_PER_PAGE, payload: rows });

	const [filteredRows, setFilteredRows] = useState();
	const [statusList] = useState([
		{
			id: 0,
			label: "Draft_copy",
			active: pathname.length === 0 || pathname === "/draft%20copy" || pathname === "/%3C%3Eclosed",
		},
		{
			id: 1,
			label: "Open",
			active: pathname.length === 0 || pathname === "/open" || pathname === "/%3C%3Eclosed",
		},
		{
			id: 2,
			label: "Pending",
			active: pathname.length === 0 || pathname === "/pending" || pathname === "/%3C%3Eclosed",
		},
		{
			id: 3,
			label: "In_Progress",
			active: pathname.length === 0 || pathname === "/in%20progress" || pathname === "/%3C%3Eclosed",
		},
		{
			id: 4,
			label: "Closed",
			active: pathname.length === 0 || pathname === "/closed",
		},
		{
			id: 5,
			label: "Improvement",
			active: pathname.length === 0 || pathname === "/improvement" || pathname === "/%3C%3Eclosed",
		},
	]);

	const filterTicketsArray = useCallback(
		(array) => {
			if (!Array.isArray(array)) return [];

			const filtered = array.filter((item) => {
				for (let i = 0; i < statusList.length; i++) {
					if (
						statusList[i].label === item.Status || // => Selon le label du status
						statusList[i].id === item.Status
					)
						// => Selon ID de l'enum
						return statusList[i]["active"];
				}

				return false;
			});

			return filtered;
		},
		[statusList]
	);

	function handleSearchFilterChanging(filter) {
		let filteredArray = rows?.tickets?.filter((row) => {
			const words = filter.split(" ");
			let found = true;

			words.forEach((word) => {
				if (word !== "") {
					let search = row?.Title.toLowerCase() + row?.No.toLowerCase() + row.ContactName.toLowerCase() + row?.AssignedContactName.toLowerCase();

					if (!search.includes(word.toLowerCase())) found = false;
				}
			});

			return found;
		});
		setSearchFilteredRows(filteredArray);
	}

	function isCellShowing(cellId) {
		for (let i = 0; i < headCells.length; i++) {
			if (headCells[i]["id"] === cellId) return headCells[i]["active"];
		}

		return false;
	}

	function handleTableHeadSettingsClick() {
		setDialog(!dialog);
	}

	function handleHeadCellsChange() {
		setHeadCells(headCells.map((item) => item));
	}

	function handleRequestSort(event, property) {
		const isAsc = orderBy === property && order === "asc";
		setOrder(isAsc ? "desc" : "asc");
		setOrderBy(property);
	}

	function handleChangePage(event, newPage) {
		setPage(newPage);
	}

	function handleChangeRowsPerPage(event) {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	}

	if (searchFilteredRows === undefined && filteredRows === undefined && rows !== undefined) {
		setFilteredRows(rows);
		setSearchFilteredRows(rows);
	}

	useEffect(() => {
		if (rows === undefined) return;

		setFilteredRows(filterTicketsArray(rows?.tickets));
		setSearchFilteredRows(filterTicketsArray(rows?.tickets));

		// console.log({ rows, filteredRows, searchFilteredRows, statusList });
	}, [rows, filterTicketsArray]);

	return (
		<React.Fragment>
			<Paper>
				<Toolbar>
					<ToolbarTitle>
						<Typography variant="h6" id="tableTitle">
							{t("tickets")}
						</Typography>
					</ToolbarTitle>

					<Spacer />

					<Search searchFilterChanging={debounce(handleSearchFilterChanging, 300)} />
					<ExportExcel values={searchFilteredRows} headers={headCells} title={t("tickets")} />
					<Tooltip title={t("table-settings")}>
						<IconButton onClick={handleTableHeadSettingsClick}>
							<SettingsIcon />
						</IconButton>
					</Tooltip>
				</Toolbar>

				<TableContainer>
					<Table aria-labelledby="tableTitle" size="small" aria-label="enhanced table">
						<EnhancedTableHead
							order={order}
							headCells={headCells}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							rowCount={searchFilteredRows !== undefined ? searchFilteredRows.length : rowsPerPage}
						/>
						<TableBody>
							{(() => {
								if (searchFilteredRows?.length > 0) {
									return stableSort(searchFilteredRows, getComparator(order, orderBy))
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row, index) => {
											return (
												<StyledTableRow
													hover
													tabIndex={-1}
													key={`${row.No}-${index}`}
													style={(() => {
														switch (row?.Priority) {
															case 0:
															case "Low":
																return {
																	boxShadow: "inset -4px 0 0 4px white, inset 0 0 0 4px " + blue[500],
																};
															case 1:
															case "Normal":
																return {
																	boxShadow: "inset -4px 0 0 4px white, inset 0 0 0 4px " + green[800],
																};
															case 2:
															case "High":
																return {
																	boxShadow: "inset -4px 0 0 4px white, inset 0 0 0 4px " + orange[500],
																};
															case 3:
															case "Emergency":
																return {
																	boxShadow: "inset -4px 0 0 4px white, inset 0 0 0 4px " + red[500],
																};
															case 4:
															case "Blocking":
																return {
																	boxShadow: "inset -4px 0 0 4px white, inset 0 0 0 4px " + red[700],
																};
															default:
																console.warn(`Unhandled priority "${row?.Priority}" in Tickets.js!`);
														}
													})()}
												>
													{isCellShowing("No") ? (
														<TableCell component="a" href={"/tickets/" + row.No}>
															{row.No}
														</TableCell>
													) : null}
													{isCellShowing("Category") ? <TableCell>{row?.Category}</TableCell> : null}
													{isCellShowing("Title") ? (
														<TableCell>
															<b>{row.Title}</b>
														</TableCell>
													) : null}
													{isCellShowing("CreationDate") ? (
														<TableCell>
															{(() => {
																let creationDate = new Date(row.CreationDate);
																if (creationDate.getTime() >= 0) return creationDate.toLocaleString();
																else return null;
															})()}
														</TableCell>
													) : null}
													{isCellShowing("Priority") ? (
														<TableCell>
															<PriorityChip size="small" mr={1} mb={1} priority={row?.Priority} />
														</TableCell>
													) : null}
													{isCellShowing("Status") ? (
														<TableCell>
															<StatusChip size="small" mr={1} mb={1} status={row?.Status} />
														</TableCell>
													) : null}
													{isCellShowing("ContactName") ? <TableCell>{row.ContactName}</TableCell> : null}
													{isCellShowing("AssignedTo") ? <TableCell>{row.AssignedContactName}</TableCell> : null}
												</StyledTableRow>
											);
										});
								} else if (rows === undefined) {
									return [...Array(rowsPerPage)].map((e, i) => (
										<StyledTableRow hover tabIndex={-1} key={i}>
											<TableCell>
												<Skeleton variant="text" />
											</TableCell>
											<TableCell>
												<Skeleton variant="text" />
											</TableCell>
											<TableCell>
												<Skeleton variant="text" />
											</TableCell>
											<TableCell>
												<Skeleton variant="text" />
											</TableCell>
											<TableCell>
												<Skeleton variant="text" />
											</TableCell>
											<TableCell>
												<Skeleton variant="text" />
											</TableCell>
											<TableCell>
												<Skeleton variant="text" />
											</TableCell>
											<TableCell>
												<Skeleton variant="text" />
											</TableCell>
										</StyledTableRow>
									));
								} else {
									return (
										<TableRow>
											<TableCell colSpan={headCells.length} align="center">
												<Typography variant="body1" component="p">
													{t("no-data")}
												</Typography>
											</TableCell>
										</TableRow>
									);
								}
							})()}
						</TableBody>
					</Table>
				</TableContainer>

				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={searchFilteredRows?.length !== undefined ? searchFilteredRows.length : rowsPerPage}
					rowsPerPage={rowsPerPage}
					page={page}
					onChangePage={handleChangePage}
					onChangeRowsPerPage={handleChangeRowsPerPage}
				/>

				<Dialog open={dialog} onClose={() => setDialog(!dialog)}>
					<DialogTitle
						style={{
							backgroundColor: variants[0].palette.primary.main,
							color: variants[0].palette.primary.contrastText,
						}}
					>
						{t("display-settings")}
					</DialogTitle>

					<Divider />

					<DialogContent>
						<TableColumnPicker headCells={headCells} headCellsHandler={handleHeadCellsChange} />
					</DialogContent>
					<DialogActions>
						<Button onClick={() => setDialog(!dialog)} color="secondary">
							{t("close")}
						</Button>
					</DialogActions>
				</Dialog>
			</Paper>
		</React.Fragment>
	);
}

function NewTicketDialog({ open, setOpen }) {
	const { t } = useTranslation();
	const snackbar = useCustomSnackbar();
	const history = useHistory();

	// #region Annulation des requêtes fetch lors de la fin de vie du composant
	const abortController = new AbortController();
	const signal = abortController.signal;

	useEffect(() => {
		return () => abortController.abort();
	}, []); // eslint-disable-line react-hooks/exhaustive-deps
	// #endregion Annulation des requêtes fetch lors de la fin de vie du composant

	// Données de la requête
	const [priorities, setPriorities] = useState();
	const [categories, setCategories] = useState();

	// On ne fait la requête que quand le dialog est ouvert pour la première fois
	useEffect(() => {
		if (open === true && priorities === undefined) {
			fetcher(signal, "GET", "ticket/categories")
				.then((data) => {
					setPriorities(data?.priorities);
					setCategories(data?.categories);
				})
				.catch((err) => {
					console.error(err);
					snackbar.showError(t("errorDuringRequest"));
				});
		}
	}, [open, priorities]); // eslint-disable-line react-hooks/exhaustive-deps

	// Ticket
	const [newTicket, setNewTicket] = useState({
		Title: "",
		Category: { No: "", Description: "" },
		Priority: "",
		Message: "",
		IsDraft: false,
		// Document
		Name: "",
		Bytes: "",
	});

	const handleTicketChange = (prop) => (event) => {
		if (prop === "IsDraft") setNewTicket({ ...newTicket, IsDraft: !newTicket.IsDraft });
		else if (prop === "Category") {
			setNewTicket({
				...newTicket,
				Category: categories.find((obj) => obj.Code == event.target.value),
			});
		} else setNewTicket({ ...newTicket, [prop]: event.target.value });
	};

	const handleTicketDialogClose = () => {
		setOpen(false);
	};

	const [loading, setLoading] = useState(false);
	const handleTicketDialogValidateClick = () => {
		if (!validRequest()) {
			snackbar.showError("Il manque des champs obligatoires !");
		} else {
			let route = newTicket.Bytes !== "" ? "ticket/with-doc" : "ticket";

			setLoading(true);
			fetcher(undefined, "POST", route, {
				...newTicket,
				Category: newTicket.Category.Code,
			})
				.then((data) => {
					if (data === 204) {
						snackbar.showError("Échec de la création du ticket, veuillez réessayer après un bref délai.");
					} else if (!!data?.ticketNo || data?.indexOf("TK") === 0) {
						let ticketNo = data?.ticketNo || data;
						history.push(`/tickets/${ticketNo}${!!data?.message ? `?err=${data?.message}` : ""}`);
					}

					setOpen(false);
				})
				.catch((err) => {
					console.error(err);
					snackbar.showError(`Une erreur est survenue lors de la requête : ${err.message}.`);
				})
				.finally(() => {
					setLoading(false);
				});
		}
	};

	function validRequest() {
		return (
			[newTicket.Title, newTicket.Category.Description, newTicket.Priority, newTicket.Message].indexOf("") === -1 &&
			typeof newTicket.IsDraft === "boolean"
		);
	}

	return (
		<React.Fragment>
			<Dialog open={open} onClose={handleTicketDialogClose} aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
				<DialogTitle
					id="form-dialog-title"
					style={{
						backgroundColor: variants[0].palette.primary.main,
						color: variants[0].palette.primary.contrastText,
					}}
				>
					{t("ticket-create-dialog-title")}
				</DialogTitle>

				<DialogContent>
					<Grid container spacing={3} pt={3}>
						<Grid item xs={12}>
							<InputLabel htmlFor="title">{t("title")}</InputLabel>
							<Input id="Title" type="text" value={newTicket?.Title} onChange={handleTicketChange("Title")} fullWidth required />
						</Grid>

						<Grid item xs={6}>
							<InputLabel htmlFor="category">{t("category")}</InputLabel>

							<Select
								labelId="category"
								id="Category"
								value={newTicket?.Category?.Code ?? ""}
								onChange={handleTicketChange("Category")}
								fullWidth
								required
							>
								{(() => {
									if (Array.isArray(categories) && categories.length > 0) {
										return categories.map((category, index) => (
											<MenuItem key={`${category.Code} - ${index}`} value={category.Code}>{`${category.Code} - ${category.Description}`}</MenuItem>
										));
									} else {
										return <MenuItem value=""></MenuItem>;
									}
								})()}
							</Select>
						</Grid>

						<Grid item xs={6}>
							<InputLabel htmlFor="priority">{t("priority")}</InputLabel>

							<Select labelId="priority" id="Priority" value={newTicket?.Priority} onChange={handleTicketChange("Priority")} fullWidth required>
								{(() => {
									if (Array.isArray(priorities)) {
										return priorities.map((priority) => (
											<MenuItem key={priority} value={priority}>
												{t(`priority-${priority.toLowerCase()}`)}
											</MenuItem>
										));
									} else {
										return <MenuItem value=""></MenuItem>;
									}
								})()}
							</Select>
						</Grid>

						<Grid item xs={12}>
							<Alert severity="info" style={{ whiteSpace: "pre-wrap" }}>
								{"Pour optimiser le traitement de votre demande, merci de joindre dans la mesure du possible des captures écrans et un cas concret avec " +
									"les éléments suivants :\n" +
									"Documents concernées\n" +
									"Résultat obtenu et/ou attendu"}
							</Alert>
						</Grid>

						<Grid item xs={12}>
							<TextField
								multiline
								variant="filled"
								label={t("message")}
								rows={4}
								fullWidth
								value={newTicket?.Message}
								onChange={handleTicketChange("Message")}
								required
							/>
						</Grid>

						<Grid item xs={12}>
							<ChooseFile fileToUpload={newTicket} setFileToUpload={setNewTicket} />
						</Grid>

						<Grid item xs={12}>
							<FormGroup row>
								<FormControlLabel
									control={<Checkbox checked={newTicket?.IsDraft} onChange={handleTicketChange("IsDraft")} name="draft" color="primary" required />}
									label={t("is-draft")}
								/>
							</FormGroup>
						</Grid>
					</Grid>
				</DialogContent>

				<DialogActions>
					<Button onClick={handleTicketDialogClose} variant="contained" color="secondary">
						{t("cancel")}
					</Button>
					<LoadingButton loading={loading} onClick={handleTicketDialogValidateClick} variant="contained" color="primary">
						{t("validate")}
					</LoadingButton>
				</DialogActions>
			</Dialog>
		</React.Fragment>
	);
}

export default function Tickets() {
	const { t } = useTranslation();

	// Dialog de création d'un ticket
	const [newTicketDialogOpen, setNewTicketDialogOpen] = useState(false);

	// Filtre de l'état selon l'URL
	const pathname = location.pathname.replace("/tickets", ""); // => "/open" || ""
	let filter = "";
	if (pathname.indexOf("/") === 0) filter = `?filter=${pathname.substring(1)}`;

	// Données du tableau et de la requête
	const rows = useFetch("get", `ticket${filter}`);
	const [priorities, setPriorities] = useState([""]);
	const [categories, setCategories] = useState([{ No: 0 }]);

	useEffect(() => {
		if (rows !== undefined && rows !== 204) {
			setPriorities(rows?.priorities);
			setCategories(rows?.categories);
		}
	}, [rows]);

	return (
		<React.Fragment>
			<Helmet title={t("tickets")} />

			<NewTicketDialog open={newTicketDialogOpen} setOpen={setNewTicketDialogOpen} priorities={priorities} categories={categories} />

			<Grid container justify="space-between">
				<Grid item>
					<Typography variant="h3" gutterBottom dislay="inline">
						{t("tickets")}
					</Typography>
				</Grid>

				<Grid item>
					<Tooltip title="Créer un ticket" aria-label="add">
						<Button size="small" variant="contained" color="primary" onClick={() => setNewTicketDialogOpen(true)} mb={3}>
							<Add />
							{"Créer un ticket"}
						</Button>
					</Tooltip>
				</Grid>
			</Grid>

			<Divider mb={6} />

			<Grid container>
				<Grid item xs={12}>
					<EnhancedTable rows={rows} pathname={pathname} />
				</Grid>
			</Grid>
		</React.Fragment>
	);
}

// Boutton ouvrant un dialog de création de ticket, dédié à l'utilisation dans la <Sidebar />
export const NewTicketButton = function () {
	const { t } = useTranslation();

	const [newTicketDialogOpen, setNewTicketDialogOpen] = useState(false);

	return (
		<>
			<NewTicketDialog open={newTicketDialogOpen} setOpen={setNewTicketDialogOpen} />

			<Grid container justify="center" align="center">
				<Grid item>
					<Button size="small" variant="contained" color="secondary" onClick={() => setNewTicketDialogOpen(true)} mb={2}>
						<Add />
						{t("ticket-create-dialog-title")}
					</Button>
				</Grid>
			</Grid>
		</>
	);
};

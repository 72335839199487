import { SnackbarProvider } from "material-ui-snackbar-provider";
import { CustomSnackbar } from "pages/components/Snackbar";
import React from "react";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";
import { isLoggedIn } from "../api";
import AuthLayout from "../layouts/Auth";
import DashboardLayout from "../layouts/Dashboard";
import PrivacyPolicyLayout from "../layouts/PrivacyPolicy";
import Page404 from "../pages/auth/Page404";
import { auth as authRoutes, dashboard as dashboardRoutes, privacy as privacyRoutes } from "./index";

const renderRoute = (props, Layout, Component, requiresAuth) => {
	return !requiresAuth || isLoggedIn() ? (
		<Layout>
			<Component {...props} />
		</Layout>
	) : (
		<Redirect to="/auth/login" />
	);
};

const childRoutes = (Layout, routes, requiresAuth) => {
	return routes.map(({ children, path, component: Component, type }, index) =>
		type === "button" ? null : children ? (
			// Route item with children
			children.map(({ path, component: Component }, index) => (
				<Route key={index} path={path} exact render={(props) => renderRoute(props, Layout, Component, requiresAuth)} />
			))
		) : (
			// Route item without children
			<Route key={index} path={path} exact render={(props) => renderRoute(props, Layout, Component, requiresAuth)} />
		)
	);
};

const Routes = () => (
	<SnackbarProvider SnackbarComponent={CustomSnackbar}>
		<Router>
			<Switch>
				{childRoutes(PrivacyPolicyLayout, privacyRoutes, false)}
				{childRoutes(DashboardLayout, dashboardRoutes, true)}
				{childRoutes(AuthLayout, authRoutes, false)}
				<Route
					render={() => (
						<AuthLayout>
							<Page404 />
						</AuthLayout>
					)}
				/>
			</Switch>
		</Router>
	</SnackbarProvider>
);

export default Routes;

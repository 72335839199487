import { TableCell, TableHead, TableRow, TableSortLabel } from "@material-ui/core";
import React from "react";

import { useTranslation } from "react-i18next";

function EnhancedTableHead(props) {
	const { order, orderBy, onRequestSort, headCells } = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};

	const { t } = useTranslation();

	const PascalToKebab = (str) =>
		str
			.trim()
			.split(/(?=[A-Z])/)
			.join("-")
			.toLowerCase();

	return (
		<TableHead>
			<TableRow>
				{headCells.map((headCell) =>
					headCell?.active ?? true ? (
						<TableCell
							key={headCell.id}
							align={headCell.alignment}
							padding={headCell.disablePadding ? "none" : "default"}
							sortDirection={orderBy === headCell.id ? order : false}
						>
							<TableSortLabel
								active={orderBy === headCell.id}
								direction={orderBy === headCell.id ? order : "asc"}
								onClick={createSortHandler(headCell.id)}
							>
								{t(PascalToKebab(headCell.id))}
							</TableSortLabel>
						</TableCell>
					) : null
				)}
			</TableRow>
		</TableHead>
	);
}

export default EnhancedTableHead;

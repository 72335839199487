import * as types from "../constants";

const initialServicesPricesHeadCells = [
	{ id: "CustomerGroup", alignment: "left", active: true },
	{ id: "Type", alignment: "left", active: true },
	{ id: "Service", alignment: "left", active: true },
	{ id: "Description", alignment: "left", active: true },
	// { id: 'Description2', alignment: 'left', active:true },
	{ id: "Unit", alignment: "left", active: true },
	{ id: "Price", alignment: "right", active: true },
];

export default function servicesPricesHeadCellsReducer(
	state = initialServicesPricesHeadCells,
	actions
) {
	switch (actions.type) {
		case types.SET_SERVICE_PRICES_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}

import { combineReducers } from "redux";

import themeReducer from "./themeReducers";
import tasksHeadCellsReducer from "./tasksHeadCellsReducer";
import ticketsHeadCellsReducer from "./ticketsHeadCellsReducer";
import recurrentInvoicesReducer from "./recurrentInvoicesReducer";
import invoiceCreditHeadCellsReducer from "./invoiceCreditHeadCellsReducer";
import customerAccountHeadCellsReducer from "./customerAccountHeadCellsReducer";
import contractHeadCellsReducer from "./contractHeadCellsReducer";
import rowsPerPageReducer from "./rowsPerPageReducer";
import servicesPricesHeadCellsReducer from "./servicesPricesHeadCellsReducer";
import productSheetHeadCellsReducer from "./productSheetHeadCellsReducer";

export default combineReducers({
	themeReducer,
	tasksHeadCellsReducer,
	ticketsHeadCellsReducer,
	recurrentInvoicesReducer,
	invoiceCreditHeadCellsReducer,
	customerAccountHeadCellsReducer,
	contractHeadCellsReducer,
	productSheetHeadCellsReducer,
	servicesPricesHeadCellsReducer,
	rowsPerPageReducer,
});

import { Button, IconButton, Snackbar } from "@material-ui/core";
import { Close as CloseIcon } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "material-ui-snackbar-provider";
import PropTypes from "prop-types";
import React from "react";

export function CustomSnackbar({
	message,
	action,
	ButtonProps,
	SnackbarProps,
	customParameters
}) {
	return (
		<Snackbar
			{...SnackbarProps}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
			autoHideDuration={3000}
			action={
				<>
					{action != null && (
						<Button color="secondary" size="small" {...ButtonProps}>
							{action}
						</Button>
					)}
					<IconButton
						aria-label="close"
						color="inherit"
						size="small"
						onClick={SnackbarProps.onClose}
					>
						<CloseIcon />
					</IconButton>
				</>
			}
		>
			<Alert
				severity={customParameters?.type}
				variant="filled"
			>
				{message}
			</Alert>
		</Snackbar>
	);
}

CustomSnackbar.propTypes = {
	message: PropTypes.string,
	action: PropTypes.string,
	ButtonProps: PropTypes.object,
	SnackbarProps: PropTypes.object,
	customParameters: PropTypes.shape({
		type: PropTypes.oneOf(["error", "warning", "info", "success"])
	})
};

export function useCustomSnackbar() {
	const snackbar = useSnackbar();

	return React.useMemo(() => {
		const showMessage = type => (
			message,
			action,
			handleAction,
			customParameters
		) =>
			snackbar.showMessage(message, action, handleAction, {
				...customParameters,
				type
			});
		return {
			...snackbar,
			showMessage: showMessage("info"),
			showInfo: showMessage("info"),
			showWarning: showMessage("warning"),
			showError: showMessage("error"),
			showSuccess: showMessage("success")
		};
	}, [snackbar]);
}

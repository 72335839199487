import { Chip } from '@material-ui/core';
import { blue, green, grey, orange, red } from "@material-ui/core/colors";
import React from 'react';
import { useTranslation } from "react-i18next";

export default function StatusChip(props) {
	const { t } = useTranslation();
	const { status, ...rest } = props;
	let color = { background: "", color: "#FFF" };

	if (status === undefined || status === "")
		return null;

	switch(status) {
		case 0: case "Draft_copy":  color.background = grey[700]; break;
		case 1: case "Open":        color.background = blue[500]; break;
		case 2: case "Pending":     color.background = green[500]; break;
		case 3: case "In_Progress": color.background = orange[500]; break;
		case 4: case "Closed":      color.color = grey[600]; break;
		case 5: case "Improvement": color.background = red[500]; break;
		default:
			color.background = " white";
			console.warn(`Unhandled status "${status}" in StatusChip.js!`);
	}

	return (
		<Chip
			{...rest}
			style={color}
			label={(() => {
				switch(status) {
					case 0: case "Draft_copy":  return t('ticket-draft_copy');
					case 1: case "Open":        return t('ticket-open');
					case 2: case "Pending":     return t('ticket-pending');
					case 3: case "In_Progress": return t('ticket-in_progress');
					case 4: case "Closed":      return t('ticket-closed');
					case 5: case "Improvement": return t('ticket-improvement');
					default: console.warn(`Unhandled status "${status}" in StatusChip.js!`);
				}
			})()}
			variant={status === "Closed" ? "outlined" : "default"}
		></Chip>
	);
}

import * as types from "../constants";

const initialInvoiceCreditHeadCells = [
	{ id: "Company", alignment: "left", active: true },
	{ id: "SalesDocumentType", alignment: "left", active: true },
	{ id: "SellToCustomerNo", alignment: "left", active: true },
	{ id: "SellToCustomerName", alignment: "left", active: true },
	{ id: "InvoiceCreditNo", alignment: "left", active: true }, // "No" seul n'avais pas la bonne traduction
	{ id: "Date", alignment: "left", active: true },
	{ id: "Amount", alignment: "right", active: true },
	{ id: "AmountIncludingVat", alignment: "right", active: true },
];

export default function invoiceCreditHeadCellsReducer(
	state = initialInvoiceCreditHeadCells,
	actions
) {
	switch (actions.type) {
		case types.SET_INVOICE_CREDIT_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}

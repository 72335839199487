export const SET_THEME = "SET_THEME";
export const SET_TASKS_HEAD_CELLS = "SET_TASKS_HEAD_CELLS";
export const SET_TICKETS_HEAD_CELLS = "SET_TICKETS_HEAD_CELLS";
export const SET_RECURRENT_INVOICES_HEAD_CELLS =
	"SET_RECURRENT_INVOICES_HEAD_CELLS";
export const SET_INVOICE_CREDIT_HEAD_CELLS = "SET_INVOICE_CREDIT_HEAD_CELLS";
export const SET_CUSTOMER_ACCOUNT_HEAD_CELLS =
	"SET_CUSTOMER_ACCOUNT_HEAD_CELLS";
export const SET_CONTRACT_HEAD_CELLS = "SET_CONTRACT_HEAD_CELLS";
export const SET_PRODUCT_SHEETS_HEAD_CELLS = "SET_PRODUCT_SHEETS_HEAD_CELLS";
export const SET_SERVICE_PRICES_HEAD_CELLS = "SET_SERVICE_PRICES_HEAD_CELLS";
export const SET_ROWS_PER_PAGE = "SET_ROWS_PER_PAGE";

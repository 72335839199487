import * as types from "../constants";

const initialTicketsHeadCells = [
	{ id: "No", alignment: "left", active: true },
	{ id: "Category", alignment: "left", active: true },
	{ id: "Title", alignment: "left", active: true },
	{ id: "CreationDate", alignment: "left", active: true },
	{ id: "Priority", alignment: "left", active: true },
	{ id: "Status", alignment: "left", active: true },
	{ id: "ContactName", alignment: "left", active: true },
	{ id: "AssignedTo", alignment: "left", active: true },
];

export default function ticketsHeadCellsReducer(
	state = initialTicketsHeadCells,
	actions
) {
	switch (actions.type) {
		case types.SET_TICKETS_HEAD_CELLS:
			return [...actions.payload];
		default:
			return state;
	}
}

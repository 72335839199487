import React, { useState }  from "react";
import styled from "styled-components";

import {
  Input, 
  InputLabel,
  IconButton
} from "@material-ui/core";


import {
  Search as MuiSearchIcon
} from "@material-ui/icons";

const SearchIcon = styled(MuiSearchIcon)`
  vertical-align: middle;
`


function Search(props){
  const {searchFilterChanging} = props;
  const [searchShowing, setSearchShowing] = useState(false);
  // const [focused, setFocused] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");

  const handleFilterChanging = (value) => {
    setSearchFilter(value);
    searchFilterChanging(value);
  }

  const handleButtonClick = () => {
    setSearchShowing(!searchShowing);
  }    
  const onEscape = () => {
    setSearchShowing(!searchShowing);
    setSearchFilter("");
    searchFilterChanging("");
  }  
  
  const handleFocusLost = () => {
    if(searchFilter === ""){
      setSearchShowing(false);
    }
  }

  return(
    <>
      <IconButton onClick={handleButtonClick}>
        <SearchIcon/>
      </IconButton>
      <InputLabel >
        {searchShowing ? 
        <Input 
          autoFocus 
          onBlur={handleFocusLost} 
          placeholder="Recherche" 
          id="search" 
          onKeyUp={e => e.key === "Escape" && onEscape()}
          value={searchFilter} 
          onChange={e => handleFilterChanging(e.target.value)}/> : null }
      </InputLabel> 
    </>
  )
}


export default Search;